import * as React from "react"
import { useIntl } from "gatsby-plugin-intl"
import Layout from "../components/layout3"
import Seo from "../components/seo"
import Gdpr from "../components/Sections/ShoptetGdpr"

const ShoptetGdpr = () => {
  const intl = useIntl()
  return (
    <Layout title="coalios s.r.o - GDPR a cookies">
      <Seo 
        lang={intl.locale} 
        title={intl.formatMessage({ id: "title" })} 
        keywords={['helios, helios inuvio, helios erp, erp, helios orange inuvio, helios brno, erp systém, solutions, helios orange, asseco solutions, asseco']}/>
      <Gdpr/>
    </Layout>
  )
}

export default ShoptetGdpr
