import React from "react"
import "./ShoptetGdpr.scss"

const ShoptetGdpr = () => {
    return (
        <section id="gdpr" className="page-section">
            <div className="container">
                <div className="row justify-content-center page-section-header">
                    <div className="col-12  col-lg-8 text-center">
                        <div className="section-title">
                            <h2 className="font-weight-bold">
                                PODMÍNKY A INFORMACE O ZPRACOVÁNÍ OSOBNÍCH ÚDAJŮ  
                            </h2>
                        </div>
                    </div>
                </div>
                <div className="row pb-5 justify-content-center">
                    <div className="col-12 col-lg-8 text-left">
                        <div className="info-panel">
                            <p>Jsme společnost coalios s.r.o.</p>
                            <p>Se sídlem Koliště 1912/13, Černá Pole, 602 00 Brno</p>
                            <p>IČ: 11998831</p>
                            <p>Zapsaná v obchodním rejstříku vedeném Krajským soudem v Brně, oddíl C, vložka 125751</p>
                            <p>Zastoupena Ing. Michalem Uhlířem</p>
                            <p>Platnost od: 31.12.2022</p>
                        </div>
                    </div>
                </div>
                <div className="row pb-5 justify-content-center">
                    <div className="col-12 col-lg-8 text-left">
                        <p className="title-description">
                            Jakožto správce osobních údajů Vás v tomto dokumentu chceme seznámit s tím, jak zpracováváme Vaše osobní údaje, pokud si objednáte doplněk  Helios iNUVIO ERP (který je skrze API propojen s Vaším internetovým obchodem u Shoptetu).  
                        </p>
                    </div>
                </div>
                <div className="row pb-5 justify-content-center">
                    <div className="col-12 col-lg-8">
                        <ul className="title-description">
                            V tomto dokumentu zjistíte:
                            <li className="offset-point">
                                proč jsou Vaše údaje námi zpracovávány,  
                            </li>
                            <li className="offset-point">
                                kde je ukládáme  
                            </li>
                            <li className="offset-point">
                                jaká máte v souvislosti se zpracováním práva.  
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="row pb-5 justify-content-center">
                    <div className="col-12 col-lg-8 text-left">
                        <p className="title-description">
                            Zpracování osobních údajů provádíme v souladu s nařízením Evropského parlamentu a Rady (EU) 2016/679 ze dne 27. dubna 2016 o ochraně fyzických osob v souvislosti se zpracováním osobních údajů a o volném pohybu těchto údajů a o zrušení směrnice 95/46/ES (obecné nařízení o ochraně osobních údajů) (dále také „GDPR“). 
                        </p>
                    </div>
                </div>
                <div className="row pb-5 justify-content-center">
                    <div className="col-12 col-lg-8 text-left">
                        <p className="title-description">
                            V případě, že nebudete jakémukoliv ustanovené rozumět, prosím kontaktujte nás na e-mail <a href="mailto:info@coalios.cz">info@coalios.cz</a> a rádi Vám vše vysvětlíme. 
                        </p>
                    </div>
                </div>
                <div className="row justify-content-center page-section-header">
                    <div className="col-12  col-lg-8 text-center">
                        <div className="section-title">
                            <h2 className="font-weight-bold">
                                I. ROZSAH VAŠICH OSOBNÍCH ÚDAJŮ, KTERÉ ZPRACOVÁVÁME 
                            </h2>
                        </div>
                    </div>
                </div>
                <div className="row pb-5 justify-content-center">
                    <div className="col-12 col-lg-8">
                        <ul className="title-description">
                            Pro aktivaci Doplňku je potřeba získat určité údaje o Vás, které jsou považovány dle GDPR za osobní údaje, a to: 
                            <li className="offset-point">
                                Kontaktní údaje (e-mail / telefon, adresa) 
                            </li>
                            <li className="offset-point">
                                Identifikace Vašeho e-shopu (webová adresa Vašeho e-shopu a jeho ID přidělené Shoptetem) 
                            </li>
                            <li className="offset-point">
                                Údaje o Vašich případných dalších administrátorech a uživatelích Shoptet 
                            </li>
                            <li className="offset-point">
                                Jakékoliv jiné osobní údaje, které nám poskytnete, abychom mohli zajistit aktivaci, provoz, podporu a údržbu našeho Doplňku.  
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="row pb-5 justify-content-center">
                    <div className="col-12 col-lg-8 text-left">
                        <p className="title-description">
                            Tyto údaje nám poskytne Shoptet, na kterém lze náš Doplněk objednat, popř. Nám je sdělíte přímo. Při provozu našeho Doplňku můžeme přijít do styku s osobními údaji Vašich zákazníků/partnerů, proto vůči takovým osobním údajům jsme v roli zpracovatele osobních údajů. Takové údaje se zavazujeme nevyužívat pro jiné účely než v souvislosti s provozem našeho Doplňku. 
                        </p>
                    </div>
                </div>
                <div className="row justify-content-center page-section-header">
                    <div className="col-12  col-lg-8 text-center">
                        <div className="section-title">
                            <h2 className="font-weight-bold">
                                II. PRÁVNÍ ZÁKLAD ZPRACOVÁNÍ 
                            </h2>
                        </div>
                    </div>
                </div>
                <div className="row pb-5 justify-content-center">
                    <div className="col-12 col-lg-8 text-left">
                        <p className="title-description">
                            Právní norma GDPR stanovuje, že zpracování osobních údajů musí být za určitým, zákonným, účelem resp. Zpracování osobních údajů musí mít právní základ pro zpracování.  
                        </p>
                    </div>
                </div>
                <div className="row pb-5 justify-content-center">
                    <div className="col-12 col-lg-8 text-left">
                        <p className="title-description">
                            Vaše osobní údaje zpracováváme pouze v přímé souvislosti s naším Doplňkem, a to pro tyto účely: 
                        </p>
                    </div>
                </div>
                <div className="row pb-5 justify-content-center">
                    <div className="col-12 col-lg-8">
                        <ul className="title-description">
                            E-mailová adresa - za účelem plnění smluvních povinností dle čl. 6 odst. 1 písm b), c) a f) GDPR, tzn., že: 
                            <li className="offset-point">
                                Je nutná pro aktivaci doplňku a jeho řádné fungování - Vaše e-mailová adresa je totiž nutný identifikátor v řadě služeb, které musí být aktivovány, aby Doplněk mohl plnit svůj účel 
                            </li>
                            <li className="offset-point">
                                Bude využívána pro informace o důležitých změnách a upozorněních v souvislosti s provozem Doplňku. 
                            </li>
                            <li className="offset-point">
                                Bude sloužit jako kontakt, na který Vám zašleme odpovědi na Vaše dotazy a budeme vyřizovat další požadavky, které na nás vznesete. 
                            </li>
                            <li className="offset-point">
                                Bude uchovávána ve vztahu k právním předpisům (např. Účetnictví), které vyžadují, abychom na Vás v této souvislosti uchovávali kontakt. 
                            </li>
                            <li className="offset-point">
                                Bude uchovávána pro účely řešení případných sporů (oprávněný zájem a ochrana našich právních nároků). 
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="row pb-5 justify-content-center">
                    <div className="col-12 col-lg-8 text-left">
                        <p className="title-description">
                            Vaši e-mailovou adresu nebudeme bez Vašeho výslovného souhlasu využívat pro zasílání obchodních sdělení. 
                        </p>
                    </div>
                </div>
                <div className="row justify-content-center page-section-header">
                    <div className="col-12  col-lg-8 text-center">
                        <div className="section-title">
                            <h2 className="font-weight-bold">
                                III. PŘÍSTUP K VAŠIM OSOBNÍM ÚDAJŮM 
                            </h2>
                        </div>
                    </div>
                </div>
                <div className="row pb-5 justify-content-center">
                    <div className="col-12 col-lg-8">
                        <ul className="title-description">
                            Náš Doplněk využívá funkce a služby třetí strany. Z důvodů zabezpečení provozu Doplňku se může stát, že budeme muset těmto třetím stranám (dle GDPR tzv. Příjemcům osobních údajů) zpřístupnit Vaše informace, které zpracováváme, a to těmto: 
                            <li className="offset-point">
                                Spol. Coalsoft s.r.o., která zajišťuje technické zázemí pro Doplněk 
                            </li>
                            <li className="offset-point">
                                Spol. DigitalOcean, LLC, která je poskytovatel serverů a služeb datových uložišť 
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="row justify-content-center page-section-header">
                    <div className="col-12  col-lg-8 text-center">
                        <div className="section-title">
                            <h2 className="font-weight-bold">
                                IV. DOBA ZPRACOVÁNÍ VAŠICH OSOBNÍCH ÚDAJŮ 
                            </h2>
                        </div>
                    </div>
                </div>
                <div className="row pb-5 justify-content-center">
                    <div className="col-12 col-lg-8 text-left">
                        <p className="title-description">
                            Zavazujeme se, že Vaše osobní údaje budeme zpracovávat pouze po dobu nezbytně nutnou k naplnění účelů, pro které je budeme shromažďovat - uvedených v článku II. 
                        </p>
                    </div>
                </div>
                <div className="row justify-content-center page-section-header">
                    <div className="col-12  col-lg-8 text-center">
                        <div className="section-title">
                            <h2 className="font-weight-bold">
                                V. VAŠE PRÁVA VE VZTAHU K OCHRANĚ OSOBNÍCH ÚDAJŮ 
                            </h2>
                        </div>
                    </div>
                </div>
                <div className="row pb-5 justify-content-center">
                    <div className="col-12 col-lg-8">
                        <ul className="title-description">
                            1. právo na přístup k osobním údajům: 
                            <p>Můžete nás kdykoliv požádat o potvrzení:</p>
                            <li className="offset-point">
                                Zda-li, případně jak a za jakým účelem jsou Vaše osobní údaje zpracovávány 
                            </li>
                            <li className="offset-point">
                                Komu je zpřístupňujeme 
                            </li>
                            <li className="offset-point">
                                Jak dlouho budou ještě zpracovávány 
                            </li>
                            <li className="offset-point">
                                Zda-li máte právo na výmaz, námitku či opravu 
                            </li>
                            <li className="offset-point">
                                Odkud jsme Vaše osobní údaje získali 
                            </li>
                            <li className="offset-point">
                                Kopii Vašich osobních údajů (první poskytnutí je zdarma, další je zpoplatněno v rozsahu 1 hodiny dle našeho aktuálního ceníku) 
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="row pb-5 justify-content-center">
                    <div className="col-12 col-lg-8">
                        <ul className="title-description">
                            2. právo na opravu:
                            <p>Můžete nás kdykoliv požádat o opravu Vašich osobních údajů, pokud původně poskytnuté jsou neúplné či nepřesné. </p>
                        </ul>
                    </div>
                </div>
                <div className="row pb-5 justify-content-center">
                    <div className="col-12 col-lg-8">
                        <ul className="title-description">
                            3. právo na výmaz („právo být zapomenut“) 
                            <p>Můžete nás kdykoliv požádat o výmaz Vašich osobních údajů, avšak takovému požadavku nebude možné vyhovět pokud: </p>
                            <li className="offset-point">
                                Jsou stále potřebné pro účely, pro které je zpracováváme 
                            </li>
                            <li className="offset-point">
                                Existují oprávněné důvody pro jejich zpracování 
                            </li>
                            <li className="offset-point">
                                Požadavek by byl v rozporu s našimi zákonnými povinnostmi 
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="row pb-5 justify-content-center">
                    <div className="col-12 col-lg-8">
                        <ul className="title-description">
                            4. právo na omezení zpracování údajů: 
                            <p>V případě sporu ohledně zpracování Vašich osobních údajů, nesmíme bez Vašeho souhlasu dále Vaše osobní údaje dále zpracovávat, máme ale právo si je ponechat pro případné právní kroky v případě právního sporu. </p>
                        </ul>
                    </div>
                </div>
                <div className="row pb-5 justify-content-center">
                    <div className="col-12 col-lg-8">
                        <ul className="title-description">
                            5. právo vznést námitku proti zpracování: 
                            <p>Můžete kdykoliv vznést námitku proti zpracování Vašich osobních údajů. Vaši námitku vyhodnotíme a neprodleně sdělíme, zda-li Vaší námitce můžeme vyhovět. Po dobu vyhodnocení Vaší námitky bude zpracování Vašich osobních údajů pozastaveno. </p>
                        </ul>
                    </div>
                </div>
                <div className="row pb-5 justify-content-center">
                    <div className="col-12 col-lg-8">
                        <ul className="title-description">
                            6. právo na přenositelnost údajů:
                            <p>Máte právo od nás získat Vaše osobní údaje, které jste nám poskytli a máte právo, aby tyto osobní údaje byly předány jinému správci osobních údajů.</p>
                        </ul>
                    </div>
                </div>
                <div className="row pb-5 justify-content-center">
                    <div className="col-12 col-lg-8">
                        <ul className="title-description">
                            7. právo podat stížnost na zpracování osobních údajů. 
                            <p>Máte právo zaslat nám jakoukoliv stížnost či připomínku ke zpracování osobních údajů, kterou zašlete na adresu <a href="mailto:info@coalios.cz">info@coalios.cz</a></p>
                        </ul>
                    </div>
                </div>
                <div className="row justify-content-center page-section-header">
                    <div className="col-12  col-lg-8 text-center">
                        <div className="section-title">
                            <h2 className="font-weight-bold">
                                VI. NAŠE POVINNOSTI VE VZTAHU KE ZPRACOVÁNÍ OSOBNÍCH ÚDAJŮ 
                            </h2>
                        </div>
                    </div>
                </div>
                <div className="row pb-5 justify-content-center">
                    <div className="col-12 col-lg-8">
                        <ul className="title-description">
                            1. Ve všech případech, kdy zpracováváme osobní údaje Vašich zákazníků a dalších osob, o kterých nám předáte prostřednictvím doplňku osobní údaje, vystupujeme jako zpracovatel osobních údajů. 
                        </ul>
                    </div>
                </div>
                <div className="row pb-5 justify-content-center">
                    <div className="col-12 col-lg-8">
                        <ul className="title-description">
                            2. Zpracování bude prováděno v elektronické formě a budeme pro Vás provádět činnosti s Osobními údaji jako je uložení Osobních údajů či přenos Osobních údajů poskytovatelům služeb třetích stran a další přistupování k nim. Tyto činnosti budou prováděny za účelem přenosu dat ze Vašich eshopů do Vašich informačních systémů, a to v souvislosti s účetními a dalšími zákonnými a smluvními povinnostmi. 
                        </ul>
                    </div>
                </div>
                <div className="row pb-5 justify-content-center">
                    <div className="col-12 col-lg-8">
                        <ul className="title-description">
                            3. Osobní údaje budeme zpracovávat, jakožto zpracovatel, pouze do dobu existence vzájemného smluvního vztahu. Do 1 měsíce ode dne skončení smluvního vztahu se zavazujeme vymazat veškeré Osobní údaje ze našich úložišť informací.  
                        </ul>
                    </div>
                </div>
                <div className="row pb-5 justify-content-center">
                    <div className="col-12 col-lg-8">
                        <ul className="title-description">
                            4. Upozorňujeme Vás, že Vaše osobní údaje si můžeme ponechat pro případy, kdy je jejich uložení vyžadováno právem České republiky nebo Evropské unie. Všechny Osobní údaje stále budete mít uloženy ve svém E-shopu (odkud Osobní údaje získáváme). 
                        </ul>
                    </div>
                </div>
                <div className="row pb-5 justify-content-center">
                    <div className="col-12 col-lg-8">
                        <ul className="title-description">
                            5. Upozorňujeme Vás, že Vaše osobní údaje si můžeme ponechat pro případy, kdy je jejich uložení vyžadováno právem České republiky nebo Evropské unie. Všechny Osobní údaje stále budete mít uloženy ve svém E-shopu (odkud Osobní údaje získáváme). 
                        </ul>
                    </div>
                </div>
                <div className="row pb-5 justify-content-center">
                    <div className="col-12 col-lg-8">
                        <ul className="title-description">
                            6. Při poskytování Služby budeme jako zpracovatel zpracovávat identifikační, kontaktní, adresní, fakturační a další údaje, které se budou týkat Vašich zákazníků a dalších osob, o kterých mi předáte Osobní údaje. 
                        </ul>
                    </div>
                </div>
                <div className="row pb-5 justify-content-center">
                    <div className="col-12 col-lg-8">
                        <ul className="title-description">
                            7. Nikdy nebudeme zpracovávat žádné citlivé údaje ve smyslu čl. 9 GDPR. 
                        </ul>
                    </div>
                </div>
                <div className="row pb-5 justify-content-center">
                    <div className="col-12 col-lg-8">
                        <ul className="title-description">
                            8. Pokud se dozvíme o porušení nebo hrozícím porušení zabezpečení Osobních údajů, náhodném nebo protiprávním zničení, ztrátě, změně nebo neoprávněném poskytnutí či zpřístupnění zpracovávaných Osobních údajů, neprodleně, nejpozději však do 48 (čtyřiceti osmi) hodin, Vás budeme informovat a co nejlépe popíšeme vzniklé či hrozící bezpečnostní riziko, abyste mohli zajistit minimalizaci porušení zabezpečení a případně přijali vhodná opatření pro minimalizaci škody.
                        </ul>
                    </div>
                </div>
                <div className="row pb-5 justify-content-center">
                    <div className="col-12 col-lg-8">
                        <ul className="title-description">
                            9. Osobní údaje budeme zpracovávat pouze na území Evropské unie.
                        </ul>
                    </div>
                </div>
                <div className="row pb-5 justify-content-center">
                    <div className="col-12 col-lg-8">
                        <ul className="title-description">
                            10. Osobní údaje budeme zabezpečovat v souladu s těmito Podmínkami a budeme ve vztahu k Osobním údajům dodržovat mlčenlivost a případně k této mlčenlivosti zavážeme také osoby oprávněné zpracovávat Osobní údaje.
                        </ul>
                    </div>
                </div>
                <div className="row pb-5 justify-content-center">
                    <div className="col-12 col-lg-8">
                        <ul className="title-description">
                            11. Budeme Vám pomáhat při zavádění a udržování vhodných technických a organizačních opatření k zabezpečení Osobních údajů, při ohlašování porušení zabezpečení Osobních údajů dozorovému úřadu nebo subjektu údajů, při posuzování vlivu na ochranu Osobních údajů a při předchozích konzultacích s dozorovým úřadem.
                        </ul>
                    </div>
                </div>
                <div className="row pb-5 justify-content-center">
                    <div className="col-12 col-lg-8">
                        <ul className="title-description">
                            12. Pokud se na Vás obrátí subjekt údajů s žádostí o výkon práva a tento výkon práva bude souviset s našimi zpracovatelskými činnostmi dle těchto Podmínek, můžete se na nás obrátit a poskytneme Vám součinnost, nejpozději do 14 dnů od chvíle, kdy se na nás obrátíte. 
                        </ul>
                    </div>
                </div>
                <div className="row pb-5 justify-content-center">
                    <div className="col-12 col-lg-8">
                        <ul className="title-description">
                            13. Pokud by se subjekt údajů obrátil přímo na nás s žádostí ve vztahu k Osobním údajům, které jsou na základě tohoto dokumentu zpracovávány, sdělíme mu, aby se s žádostí obrátil přímo na Vás. Vy jste následně odpovědní za vyřízení takové žádostí a my Vám případně poskytneme součinnost.
                        </ul>
                    </div>
                </div>
                <div className="row pb-5 justify-content-center">
                    <div className="col-12 col-lg-8">
                        <ul className="title-description">
                            14. Můžete náspožádat o kontrolu (včetně auditu či inspekce), abyste si ověřili, zda jsou dodržovány povinnosti týkající se zpracování Osobních údajů. Náklady na takovou kontrolu však ponesete Vy. Žádost o audit nám musíte zaslat na naši e-mailovou adresu. Poté, co obdržíme žádost, dohodneme se společně na termínu provedení auditu, bezpečnostních opatřeních a způsobu zajištění dodržování závazku mlčenlivosti všech osob podílejících se na auditu a začátku, rozsahu a době trvání auditu. Pokud se nebudeme schopni ani do 30 dnů na tomto dohodnout, podmínky určí naše společnost.
                        </ul>
                    </div>
                </div>
                <div className="row pb-5 justify-content-center">
                    <div className="col-12 col-lg-8">
                        <ul className="title-description">
                            15. Zavazujeme se osobní údaje zabezpečit před kybernetickými útoky nejvhodnějším způsobem s přihlédnutím k povaze Osobních údajů a stavu techniky, v souladu s čl. 32 GDPR a zavazujeme se dodržovat dostatečná technická a organizační opatření.
                        </ul>
                    </div>
                </div>
                <div className="row justify-content-center page-section-header">
                    <div className="col-12  col-lg-8 text-center">
                        <div className="section-title">
                            <h2 className="font-weight-bold">
                                VII. VAŠE POVINNOSTI VE VZTAHU KE ZPRACOVÁNÍ OSOBNÍCH ÚDAJŮ 
                            </h2>
                        </div>
                    </div>
                </div>
                <div className="row pb-5 justify-content-center">
                    <div className="col-12 col-lg-8">
                        <ul className="title-description">
                            1. Pokud jste zpracovatelé Osobních údajů, zaručujete se, že příslušný správce, který Vám osobní údaje poskytl, s tímto předáním Osobních údajů souhlasí a předání schválil. 
                        </ul>
                    </div>
                </div>
                <div className="row pb-5 justify-content-center">
                    <div className="col-12 col-lg-8">
                        <ul className="title-description">
                            2. Souhlasíte s tím, abychom zapojili jakéhokoliv dalšího zpracovatele, přičemž Vás budeme předem informovat.
                        </ul>
                    </div>
                </div>
                <div className="row pb-5 justify-content-center">
                    <div className="col-12 col-lg-8">
                        <ul className="title-description">
                            3. Souhlasíte s tím, abychom zapojili zejména společnost coalsoft s.r.o., která zajišťuje uložení Osobních údajů. 
                        </ul>
                    </div>
                </div>
                <div className="row pb-5 justify-content-center">
                    <div className="col-12 col-lg-8">
                        <ul className="title-description">
                            4. Zavazujete, že veškeré povinnosti vůči Doplňku, včetně zřízení uživatelského účtu, si budete plnit sami a my nejsme odpovědní za zpracování Osobních údajů prostřednictvím nástroje, který společnost provozuje. 
                        </ul>
                    </div>
                </div>
                <div className="row pb-5 justify-content-center">
                    <div className="col-12 col-lg-8">
                        <ul className="title-description">
                            5. V případě, že zapojíme jakéhokoli dalšího zpracovatele, zajistíme, aby dodržoval alespoň stejné podmínky pro zpracování osobních údajů, ke kterým se zavazujeme v rámci těchto podmínek.
                        </ul>
                    </div>
                </div>
                <div className="row pb-5 justify-content-center">
                    <div className="col-12 col-lg-8">
                        <ul className="title-description">
                            6. Musíte ve vztahu k Vašim Osobním údajům dodržovat všechny povinnosti, které na Vás jako správce dopadají. Musíte tedy zejména informovat subjekty údajů o zpracování Osobních údajů, získat souhlas se zpracováním osobních údajů, pokud je to potřeba, vyřizovat žádosti subjektů údajů, které se týkají zejména realizace jejich práv a dostatečně zabezpečovat Osobní údaje v souladu s čl. 32 GDPR.
                        </ul>
                    </div>
                </div>
                <div className="row pb-5 justify-content-center">
                    <div className="col-12 col-lg-8">
                        <ul className="title-description">
                            7. Pokud porušíte některou ze svých povinností jako správci Osobních údajů a sdělíte nám instrukce, které budou v rozporu s právními předpisy, zejména s GDPR, jste odpovědni za škodu, která nám tímto jednáním vznikla (zejména za pokuty udělené dozorových úřadem a škodu a újmu, která vznikla subjektům údajů, včetně nemajetkové újmy) 
                        </ul>
                    </div>
                </div>
                <div className="row justify-content-center page-section-header">
                    <div className="col-12  col-lg-8 text-center">
                        <div className="section-title">
                            <h2 className="font-weight-bold">
                                DOHLED NA ZPRACOVÁNÍM OSOBNÍCH ÚDAJŮ 
                            </h2>
                        </div>
                    </div>
                </div>
                <div className="row pb-5 justify-content-center">
                    <div className="col-12 col-lg-8 text-left">
                        <p className="title-description">
                            Dohled nad zpracováním osobních údajů má Úřad pro ochranu osobních údajů, u kterého můžete v případě Vaší nespokojenosti podat stížnost. Více se dozvíte na <a href="htts://uoou.cz">www.uoou.cz</a>.  
                        </p>
                    </div>
                </div>
                <div className="row pb-5 justify-content-center">
                    <div className="col-12 col-lg-8 text-left">
                        <p className="title-description">
                            V případě změn těchto podmínek Vás budeme náležitě informovat.  
                        </p>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ShoptetGdpr